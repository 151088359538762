import { Payment } from "./Network";

const header = '"Active?", "Vendor Name*", "Vendor Id", "Invoice #*", "Invoice Date*", "Due Date*", "GL Posting Date", "Exchange Rate", "Description", "P.O. Number", "Payment Terms Name", "Auto Pay Setup", "Created from an eBill", "Item Name", "Item ID", "Source of bill creation", "Use Bill Description for Expense Description", "Default Invoice Number to Invoice Date", "Bill is Auto-Saved", "Bill Line Item Amount", "Bill Line Item Account Name", "Bill Line Item Account Number", "Bill Line Item Department Name", "Bill Line Item Department Short Name", "Bill Line Item Location ID", "Bill Line Item Location Name", "Bill Line Item Job Name", "Bill Line Item Job ID", "Bill Line Item Customer Name", "Bill Line Item Customer ID", "Bill Line Item Billable?", "Bill Line Item Description", "Bill Line Item Line Item Type", "Bill Line Item Item Name", "Bill Line Item Item ID", "Bill Line Item Qty", "Bill Line Item Unit Cost", "Bill Line Item Employee ID", "Bill Line Item Employee Last Name", "Bill Line Item Class ID", "Bill Line Item Class Name", "Bill Line Item Order*"\n'
const buckets = new Map([
	["Spotify Ad Studio",      "614 Spotify Ad Studio 2025"],
	["Spotify Direct",         "615 Spotify Direct 2025"],
	["Amazon",                 "611 Amazon"],
	["Media",                  "Media"],
	["Publisher Host Read Ad", "Publisher Host Read Ad"],
])

export function getBillCSV(payments: Array<Payment>, invoiceNum: string) : Blob{
    const invoiceDate = new Date().toLocaleDateString()
    const lines = payments.map(p => [
        "Active", p.legalEntity, "", invoiceNum, invoiceDate, invoiceDate, invoiceDate, "", "Voice talent", 
        "", "", "", "", "", "", "", "", "", "", "$" + p.pay, "", "5120", "", "", "", "", "", "", "", "", "", 
        [p.title, new Date(p.created).toLocaleDateString(), p.rate].join(" - "), "", "", "", "", "", "", "", 
        "", buckets.get(p.bucket), ""
    ].map(s => `"${s}"`).join(","))
    return new Blob([header + lines.join("\n")])
}