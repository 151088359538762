import { useContext } from 'react'
import { AppContext } from '../util/AppContext'
import './Users.scss'

export default function Users() {
    const {users} = useContext(AppContext)

    return <div className="users">
        {users.map(u => <div key={u._id} className="user-row">
            <div className='image'><img src={u.image} alt='user'/></div>
            <div className='name'>{u.name}</div>
            <div className='role'>{u.roles.join(', ')}</div>
        </div>)}
      </div>
  }