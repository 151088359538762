import { createContext } from "react"
import { User } from "./Network"
import { Project, Workflow } from "./Workflow"

export const AppContext = createContext<{
    users: User[]
    workflow?: Workflow
    user?: User|undefined
    setUser: (u: User) => void
    projects: Map<string,Project>
    updateProject: (p: Project) => void
    refresh: () => Promise<void>
}>({users: [], setUser: () => {}, projects: new Map<string,Project>(), updateProject: () => {}, refresh: async () => {}})
