import "./Login.scss"
import {CredentialResponse, GoogleLogin} from "@react-oauth/google"
import {useEffect, useRef, useState} from "react"
import {Navigate, useNavigate} from "react-router-dom"
import Network, {VideoResponse} from "../util/Network"
import Icons from "../util/Icons"

export default function Login() {
    const [video, setVideo] = useState<VideoResponse|undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (!video && !loading) {
            setLoading(true)
            Network.getVideo().then(v => {
                setVideo(v)
                if (videoRef.current && v) {
                    videoRef.current.src = v.link
                }
            })
        }
    },[video, loading])

    async function success(response: CredentialResponse) {
        setErrorMessage("")
        if (response.credential && await Network.login(response.credential)) {
            navigate('/')
        } else {
            setErrorMessage("Server Error")
        }
    }

    function error() {
        setErrorMessage("Login failed")
    }

    return Network.isLoggedIn() ? <Navigate to="/"/> :
        <div className="login">
        <video className='video' loop autoPlay muted ref={videoRef}/>
        <div className='dialog'>
            <div className='title'><Icons.Logo/>Kitchen</div>
            <GoogleLogin onSuccess={success} onError={error} auto_select={true} size="large" use_fedcm_for_prompt={true}/>
            <div className='error'>{errorMessage}</div>
        </div>
        {video && <div className='credits'>
            <a href="https://www.pexels.com">
                <img className="pexels" src="https://images.pexels.com/lib/api/pexels-white.png" alt="pexels"/>
            </a>
            <div>This <a href={video.page}>Video</a> was taken by <a href={video.user}>{video.name}</a> on Pexels.</div>
        </div>}
    </div>
}