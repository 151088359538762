import "./Attachments.scss"
import {Project} from "../util/Workflow";
import React, {useContext, useState} from "react";
import {Drop} from "../components/Drop";
import {FileIcon} from "../components/FileIcon";
import Network from "../util/Network";
import { AppContext } from "../util/AppContext";
import Dialog from "./Dialog";

interface Props {
    project?: Project
}

export function Attachments(props: Props) {
    const [newAttachments, setNewAttachments] = useState<Array<File>>([])
    const [loading, setLoading] = useState(false)
    const {updateProject} = useContext(AppContext)

    function close() {
        setNewAttachments([])
    }

    async function attach() {
        if (!props.project)
            return
        setLoading(true)
        let newProj = props.project
        for (let file of newAttachments) {
            const url = await Network.uploadFile(props.project._id, file.name, file.type, await file.arrayBuffer())
            if (url) {
                const proj = await Network.addAttachment(props.project._id, file.name, url, file.type)
                if (proj)
                    newProj = proj
            }
        }
        updateProject(newProj)
        setLoading(false)
        close()
    }

    function addFile(file: File) {
        setNewAttachments([...newAttachments, file])
    }

    return <Dialog className='attachments' onClose={close} loading={loading}>
        <div className='project-title'>Project: {props.project?.title}</div>
        <div>
            <div className='title'>Attachments:</div>
            <div className='files'>
                {props.project?.attachments?.map(a => <FileIcon attachment={a} key={a.filename}/>)}
                {newAttachments.map(a => <FileIcon attachment={{text: URL.createObjectURL(a), filename: a.name, contentType: a.type}} key={a.name}/>)}
            </div>
            {!props.project?.attachments && (newAttachments.length === 0) && <div>No attachments yet</div>}
        </div>
        <Drop drop={addFile}>Click to upload a file, or drag and drop here</Drop>
        <div className='buttons'>
            <div className='cancel button' onClick={close}>Cancel</div>
            <div className={'create button' + ((newAttachments.length > 0) ? "" : " disabled")}
                    onClick={attach}>Attach
            </div>
        </div>
    </Dialog>
}