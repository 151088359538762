import "./Payments.scss"
import { useEffect, useRef, useState } from "react"
import network, { Payment } from "../util/Network"
import Icons from "../util/Icons"
import { UploadPayments } from "../dialogs/UploadPayments"
import { getBillCSV } from "../util/BillCom"
import { Invoice } from "../dialogs/Invoice"

interface talent {
    name: string
    total: number
    payments: Array<Payment>
}

export function Payments() {
    const [invoice, setInvoice] = useState<string|undefined>(undefined)
    const [invoices, setInvoices] = useState<Array<string>|undefined>(undefined)
    const [talents, setTalents] = useState<Array<talent>|undefined>(undefined)
    const [upload, setUpload] = useState(false)
    const [showInvoice, setShowInvoice] = useState<Array<Payment>|undefined>(undefined)

    useEffect(() => {
        if (!invoices) {
            network.getInvoices().then(is => {
                setInvoices(is?.sort((a,b) => (b.substring(4)+b.substring(0,4)).localeCompare(a.substring(4)+a.substring(0,4))))
                if (is && is.length > 0) 
                    setInvoice(is[0])
            })
        }
    }, [])

    useEffect(() => {
        setTalents(undefined)
        if (invoice) 
            network.getPayments(invoice).then(ps => {
                const ts = new Map<string, talent>()
                ps?.forEach(p => {
                    const t = ts.get(p.name) ?? {name:p.name,total:0,payments:[]}
                    t.total += p.pay
                    t.payments.push(p)
                    ts.set(p.name, t)
                })

                setTalents(Array.from(ts.values()).sort((a,b) => a.name.localeCompare(b.name)))
            })
    }, [invoice])

    function Talent({talent, index}:{talent:talent, index: number}) {
        const [open, setOpen] = useState(false)

        return <><div className={`row ${(index%2==0)?"even":"odd"}`}>
            <div className="name" onClick={() => setOpen(!open)}><Icons.Expand className={open ? "expand open" : "expand"}> </Icons.Expand>{talent.name}</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div className="pay" onClick={() => setShowInvoice(talent.payments)}><b>${talent.total}</b></div>
        </div>
        {open && talent.payments.map(p => <div key={p._id} className={`row ${(index%2==0)?"even":"odd"}`}>
            <div></div>
            <div>{p.project}</div>
            <div>{p.title}</div>
            <div>{new Date(p.created).toLocaleDateString()}</div>
            <div>{p.rate}</div>
            <div>${p.pay}</div> 
        </div>)}
        </>
    }

    function download() {
        if (!talents || !invoice) 
            return

        const a = document.createElement('a');
        a.download = `invoice-for-bill.${invoice}.csv`
        a.href = URL.createObjectURL(getBillCSV(talents.flatMap(t => t.payments), invoice))
        a.addEventListener('click', (e) => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
        })
        a.click()
    }

    return <div className="payments">
        <div className='invoice'>Invoice: <select onChange={e => {setInvoice(e.target.value)}}>{
            invoices?.map(i => <option key={i} value={i}>{i}</option>)
        }</select>
            <Icons.AddCircle onClick={() => setUpload(true)}/>
            <Icons.Download onClick={() => download()}/>
        </div>
        <div className='table'>
            <div>Talent</div><div>Project</div><div>Title</div><div>Date</div><div>Rate</div><div>Pay</div>
            {talents?.map((t, i) => <Talent talent={t} key={t.name} index={i}/>)}
        </div>
        {upload && <UploadPayments onClose={() => setUpload(false)}/>}
        {showInvoice && <Invoice onClose={() => setShowInvoice(undefined)} payments={showInvoice}/>}
    </div>
}
