import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import Login from "./pages/Login"
import {GoogleOAuthProvider} from "@react-oauth/google"
import {Protected} from "./util/Protected"
import { Projects } from './pages/Projects'
import Users from './pages/Users'
import { Payments } from './pages/Payments'

const router = createBrowserRouter([
    {path: '/login', element: <Login />},
    {path: '/', element: <Protected><App /></Protected>, children: [
      {path: '/project/:proj_id/comments', element: <Projects mode='comments'/>},
      {path: '/project/:proj_id/attach', element: <Projects mode='attach'/>},
      {path: '/project/:proj_id/assign', element: <Projects mode='assign'/>},
      {path: '/project/:proj_id/step/:dir', element: <Projects mode='step'/>},
      {path: '/project/:proj_id', element: <Projects mode='view'/>},
      {path: '/new/:proj_id', element: <Projects mode='new'/>},
      {path: '/new', element: <Projects mode='new'/>},
      {path: '/users', element: <Users/>},
      {path: '/payments', element: <Payments/>},
      {path: '', element: <Projects/>}
    ]},
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId='159139330148-tj6nuscu8frjgda3ig5vbq4q6t48lm74.apps.googleusercontent.com'>
          <RouterProvider router={router}/>
      </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
