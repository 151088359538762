import React, {useContext, useState} from "react"
import "./ViewProject.scss"
import {Project, State} from "../util/Workflow"
import {EnumBubble} from "../components/EnumBubble"
import {UserBubble} from "../components/UserBubble"
import {FileIcon} from "../components/FileIcon";
import {useNavigate} from "react-router-dom";
import {RelativeTime} from "../util/RelativeTime";
import { AppContext } from "../util/AppContext"
import Icons from "../util/Icons"
import Dialog from "./Dialog"

interface Props {
    project?: Project
}

export function ViewProject(props: Props) {
    const [showAll, setShowAll] = useState("")
    const navigate = useNavigate()
    const {user} = useContext(AppContext)
    const project = props.project

    function close() {
        setShowAll("")
    }

    function gotoComments() {
        setShowAll("")
        navigate(`/project/${project?._id}/comments`)
    }

    let badge = project?.comments?.length ?? 0
    if (project && user?.read?.hasOwnProperty(project._id) && project.comments) {
        const ts = user.read[project._id]
        badge = project.comments.filter(c => c.timestamp > ts).length
    }

    return <Dialog className='view-project' onClose={close}>
        <div className='prompt'>{project?.title}<span className='comment' onClick={gotoComments}>{badge > 0 && <span className='badge'>{badge}</span>}<Icons.Comment/></span></div>
        <div className='details'>
            <EnumBubble value={project?.state} enum={State}/>
            <span>Created: {project?.created && <RelativeTime date={project.created}/>}</span>
            <span>Updated: {project?.updated && <RelativeTime date={project.updated}/>}</span>
            <span>Deadline: {project?.deadline && <RelativeTime date={project?.deadline}/>}</span>
            <span>Delivery: {project?.delivery && <RelativeTime date={project?.delivery}/>}</span>
        </div>
        <div className='assignees'>
            <div>Producer:</div>
            <UserBubble user={project?.producer}/>
            <div>Voice:</div>
            <UserBubble user={project?.voice}/>
            <div>Writer:</div>
            <UserBubble user={project?.writer}/>
        </div>
        <div className='editor'>
            Brief: {project?.briefs && (project.briefs.length ?? 0) > 1 &&
            <span>{project.briefs.length - 1} previous versions</span>}
            <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.briefs && (project.briefs.length > 0)) ? project.briefs[project.briefs.length - 1].text : ''}}/>
        </div>
        {project?.scripts && <div className='editor'>
            Script: {(project.scripts.length ?? 0) > 1 &&
            <span>{project.scripts.length - 1} previous versions</span>}
            <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.scripts && (project.scripts.length > 0)) ? project.scripts[project.scripts.length - 1].text : ''}}/>
        </div>}
        {project?.notes && <div className='editor'>
            Script: {(project.notes.length ?? 0) > 1 &&
            <span>{project.notes.length - 1} previous versions</span>}
            <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.notes && (project.notes.length > 0)) ? project.notes[project.notes.length - 1].text : ''}}/>
        </div>}
        <div className='recording'>
            <div className='title'>Recording: {project?.recordings && project.recordings.length > 1 &&
                <span className='show-all'
                        onClick={() => setShowAll('recordings')}>{project.recordings.length - 1} previous recordings</span>}</div>
            {project?.recordings && project.recordings.length > 0 ? <div className='recordings'>
                {showAll === 'recordings' ? <>
                    {project.recordings.map((r, i) => <div className='revision' id={`recording${i}`}>
                        <audio controls>
                            <source src={r.text}/>
                        </audio>
                        Recorded by <UserBubble user={r.user}/><RelativeTime date={r.timestamp}/>
                    </div>)}</> : <div className='revision'>
                    <audio controls>
                        <source src={project.recordings[project.recordings.length - 1].text}/>
                    </audio>
                    Recorded by <UserBubble user={project.recordings[project.recordings.length - 1].user}/><RelativeTime date={project.recordings[project.recordings.length - 1].timestamp}/>
                </div>
                }
            </div> : <div>
                No recording yet
            </div>}
        </div>
        {project?.feedbacks && <div className='editor'>
            Feedback: {(project.feedbacks.length ?? 0) > 1 &&
            <span>{project.feedbacks.length - 1} previous versions</span>}
            <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.feedbacks && (project.feedbacks.length > 0)) ? project.feedbacks[project.feedbacks.length - 1].text : ''}}/>
        </div>}

        <div className='attachments'>
            <div className='title'>Attachments:</div>
            <div className='files'>
                {project?.attachments?.map(a => <FileIcon attachment={a}/>)}
            </div>
            {!project?.attachments && <div>No attachments yet</div>}
        </div>
        <div className='buttons'>
            <div className='cancel button' onClick={close}>Close</div>
        </div>
    </Dialog>
}