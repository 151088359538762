import React from "react"
import "./FileIcon.scss"
import Icons from "../util/Icons"

interface Props {
    attachment: {text: string, filename: string, contentType: string}
}

export function FileIcon({attachment}: Props) {

    return <div className='file-icon'>
        <a href={attachment.text} target='_blank' rel='noreferrer'>
            {attachment.contentType.startsWith('audio/') ? <Icons.AudioFile/> : attachment.contentType.startsWith('video/') ?
                <Icons.VideoFile/> : <Icons.File/>}
        </a>
        <div className='name'>{attachment.filename}</div>
    </div>
}