import "./Comments.scss"
import {Project} from "../util/Workflow";
import React, {useContext, useState} from "react";
import Network from "../util/Network";
import {Editor} from "../components/Editor";
import {UserBubble} from "../components/UserBubble";
import { AppContext } from "../util/AppContext"
import {RelativeTime} from "../util/RelativeTime";
import network from "../util/Network";
import Dialog from "./Dialog";

interface Props {
    project?: Project
}

export function Comments(props: Props) {
    const [loading, setLoading] = useState(false)
    const [newComment, setNewComment] = useState<string>("")
    const {users, setUser, updateProject} = useContext(AppContext)
    
    function close() {
        setNewComment("")
    }
    
    async function comment() {
        if (newComment !== "" && props.project) {
            setLoading(true)
            const project = await Network.comment(props.project._id, newComment)
            setLoading(false)
            if (project) {
                setNewComment("")
                updateProject(project)
                network.updateRead(project._id, project.comments.map(c => c.timestamp).reduce((a,b) => Math.max(a,b), 0)).then(u => {if (u) setUser(u)})
            }
        }
    }

    function getUser(uid: string) {
        return users?.find(u => u._id === uid)
    }

    return <Dialog className='comments' onClose={close} loading={loading}>
        {props.project?.comments && props.project.comments.map((comment, i) => <div className='comment' key={`comment-${i}`}>
            <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: comment.text}}/>
            <div className='details'><UserBubble user={getUser(comment.user)}/><RelativeTime date={comment.timestamp}/></div>
        </div>)}
        <Editor value={newComment} onChange={setNewComment}/>
        <div className='buttons'>
            <div className='cancel button' onClick={close}>Cancel</div>
            <div className={'create button' + (newComment ? "" : " disabled")} onClick={comment}>Comment</div>
        </div>
    </Dialog>
}