import { useNavigate } from "react-router-dom";
import "./Dialog.scss";
import Icons from "../util/Icons";
import { Loader } from "../components/Loader";
import { useEffect } from "react";

interface Props {
    className: string
    loading?: boolean
    onClose: () => void
    dontNavigate?: boolean
}

export default function Dialog(props: React.PropsWithChildren<Props>) {
    const navigate = useNavigate()
    
    function close() {
        if (props.dontNavigate !== true) 
            navigate('/')
        props.onClose()
    }

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.removeProperty("overflow")
        }
    }, [])

    return <div className={`dialog ${props.className}`} onClick={close}>
        <div className='content' onClick={e => e.stopPropagation()}>
            <div className='close' onClick={close}><Icons.Close/></div>
            {props.children}
        </div>
        <div className={'loader' + (props.loading ? " loading" : "")}><Loader/></div>
    </div>
}