import "./UserBubble.scss"
import {CSSProperties, ReactEventHandler, useContext, useEffect, useState} from "react"
import {User} from "../util/Network"
import {defaultColors, hash, hashColors} from "../util/Hash"
import { AppContext } from "../util/AppContext"

interface UserBubbleProps {
    user?: User | string
    onClick?: ReactEventHandler<HTMLDivElement>
}

function getAvatar(s: string) {
    return `/avatar-0${hash(s) % 5+1}.png`
}

export function UserBubble(props: UserBubbleProps) {
    const [name, setName] = useState<string>()
    const [image, setImage] = useState<string>()
    const [bg, setBg] = useState<CSSProperties>({})
    const context = useContext(AppContext)

    useEffect(() => {
        if (!props.user) {
            setName("Unassigned")
            setBg(defaultColors)
            setImage(undefined)
        } else if (typeof props.user === 'string') {
            const u = context?.users?.find(u => u._id === props.user)
            if (u) {
                setName(u.name)
                setImage(u.image ?? getAvatar(u._id))
                setBg(hashColors(u._id))
            } else {
                setName("Unknown user")
                setBg(defaultColors)
                setImage(undefined)
            }
        } else {
            setName(props.user.name)
            setImage(props.user.image ?? getAvatar(props.user._id))
            setBg(hashColors(props.user._id))
        }
    }, [props.user]);

    return <div className='user' style={bg} onClick={props.onClick}>
        {image ? <img src={image} alt='user'/> : <div/>}
        <div className='name'>{name}</div>
    </div>
}