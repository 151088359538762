import "./Invoice.scss"
import { Payment } from "../util/Network"
import Dialog from "./Dialog"

interface Props {
    onClose: () => void
    payments: Array<Payment>
}

export function Invoice(props:Props) {
    const invoiceNum = parseInt(props.payments[0].invoice)
    const invoiceDate = new Date(2000+invoiceNum % 100, invoiceNum / 10000 - 1, invoiceNum / 100 % 100 + 1)
    const dueDate = new Date(2000+invoiceNum % 100, invoiceNum / 10000 - 1, invoiceNum / 100 % 100 + 30)

    return <Dialog className="invoice-dialog" onClose={props.onClose} dontNavigate={true}>
        <div className='heading'>INVOICE</div>
        <div className='details'>
            <div className='talent-name'>{props.payments[0].name}</div>
            <div className='invoice-details'>
                <div className='title'>Invoice #:</div><div className='invoice-number'>{invoiceNum}</div>
                <div className='title'>Invoice Date:</div><div className='invoice-date'>{invoiceDate.toLocaleDateString()}</div>
                <div className='title invoice-total'>Amount Due:</div><div className='invoice-total'>${props.payments.reduce((t, p) => t + p.pay, 0)}.00</div>
            </div>
        </div>
        <div className='bill-to'>
            <div className='title'>Bill To:</div>
            <div className='address'>
                AUDIOis, inc.<br/>
                4 E 27th Street<br/>
                PO Box 20015<br/>
                New York, NY 10001<br/>
                United States<br/>
            </div>
        </div>
        <table className='due'>
            <tr><th>Due Date</th><th>Terms</th></tr><tr><td>{dueDate.toLocaleDateString()}</td><td>Net 30</td></tr>
        </table>
        <table className='contents'>
            <tr><th>Date</th><th>Title</th><th>Rate</th><th>Total</th></tr>
            {props.payments.map(p => <tr>
                <td>{new Date(p.created).toLocaleDateString()}</td>
                <td className="title">{p.title}</td>
                <td>{p.rate}</td>
                <td>${p.pay}.00</td>
            </tr>)}
            <tr><th className='total-title' colSpan={3}>Total:</th>
            <td>${props.payments.reduce((t, p) => t + p.pay, 0)}.00</td>
            </tr>
        </table>
    </Dialog>
}