import "./Assign.scss"
import {Project} from "../util/Workflow";
import React, {useContext, useState} from "react";
import { AppContext } from "../util/AppContext"
import {UsersDropdown} from "../components/UsersDropdown";
import Network from "../util/Network";
import Dialog from "./Dialog";

interface Props {
    project?: Project
}

export function Assign(props: Props) {
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const [producer, setProducer] = useState<string>()
    const [voice, setVoice] = useState<string>()
    const [writer, setWriter] = useState<string>()
    const {updateProject} = useContext(AppContext)

    function close() {
        setProducer(undefined)
        setVoice(undefined)
        setWriter(undefined)
    }

    async function assign() {
        if (!props.project)
            return
        setLoading(true)
        const project = await Network.assign(props.project._id, {voice, producer, writer})
        setLoading(false)
        if (project) {
            updateProject(project)
            close()
        }
    }

    const assignee = props.project && context?.workflow?.get(props.project.track)?.states?.get(props.project.state)?.assignee

    return <Dialog className='assign' onClose={close} loading={loading}>
        <div className='project-title'>Project: {props.project?.title}</div>

        {assignee !== 'voice' && assignee !== 'writer' && <div className='assign'>Producer: <UsersDropdown user={producer ?? props.project?.producer} setUser={setProducer}/></div>}
        {assignee !== 'producer' && assignee !== 'writer' && <div className='assign'>Voice: <UsersDropdown user={voice ?? props.project?.voice} setUser={setVoice}/></div>}
        {assignee !== 'producer' && assignee !== 'voice' && <div className='assign'>Writer: <UsersDropdown user={writer ?? props.project?.writer} setUser={setWriter}/></div>}

        <div className='buttons'>
            <div className='cancel button' onClick={close}>Cancel</div>
            <div className={'create button' + ((producer || voice || writer) ? "" : " disabled")} onClick={assign}>Assign</div>
        </div>
    </Dialog>
}