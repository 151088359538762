import "./UsersDropdown.scss"
import {useContext, useState} from "react"
import {UserBubble} from "./UserBubble"
import { AppContext } from "../util/AppContext"

interface Props {
    user?:string
    setUser:(user:string) => void
}

export function UsersDropdown({user, setUser}:Props) {
    const [show, setShow] = useState(false)
    const context = useContext(AppContext)

    return <div className='users-dropdown'>
        <UserBubble user={user} onClick={()=>setShow(!show)}/>
        <div className={'list'+(show?" show":"")}>
            {context?.users.map(u => <UserBubble user={u} key={u._id} onClick={() => {
                setShow(false)
                setUser(u._id)
            }}/>)}
        </div>
    </div>

}