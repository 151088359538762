import React, {useContext, useEffect, useState} from "react"
import "./Projects.scss"
import {getAssignee, Project, State, Track} from "../util/Workflow"
import {UserBubble} from "../components/UserBubble"
import {EnumBubble} from "../components/EnumBubble"
import {Step} from "../dialogs/Step"
import { AppContext } from "../util/AppContext"
import {Attachments} from "../dialogs/Attachments";
import {useNavigate, useParams} from "react-router-dom";
import {Assign} from "../dialogs/Assign";
import {Comments} from "../dialogs/Comments";
import {RelativeTime} from "../util/RelativeTime";
import network from "../util/Network"
import { NewProject } from "../dialogs/NewProject"
import { ViewProject } from "../dialogs/ViewProject"
import Icons from "../util/Icons"

interface Props {
    mode?: 'new'|'comments'|'view'|'step'|'assign'|'attach'
}

export function Projects(props: Props) {
    const context = useContext(AppContext)
    const {user, setUser, projects, refresh} = useContext(AppContext)
    const [assignProj, setAssignProj] = useState<Project|undefined>(undefined)
    const [attachmentProj, setAttachmentProj] = useState<Project|undefined>(undefined)
    const [commentsProj, setCommentsProj] = useState<Project|undefined>(undefined)
    const [newProj, setNewProj] = useState(false)
    const [stepProj, setStepProj] = useState<Project|undefined>(undefined)
    const [viewProj, setViewProj] = useState<Project|undefined>(undefined)
    const [currentDir, setCurrentDir] = useState<'next'|'prev'>('next')

    const [sort, setSort] = useState("")
    const [refreshing, setRefreshing] = useState(false)
    const navigate = useNavigate()
    const {proj_id, dir} = useParams()

    function getState(proj: Project) {
        return context?.workflow?.get(proj.track)?.states?.get(proj.state)
    }

    async function doRefresh() {
        setRefreshing(true)
        await refresh()
        setRefreshing(false)
    }

    function getAssigneeName(p: Project) {
        const assignee = getAssignee(p, context?.workflow)
        if (assignee) {
            return context?.users.find(u => u._id === assignee)?.name ?? ""
        }
        return ""
    }

    function sortProjects(p: Array<Project>): Array<Project> {
        let f: ((a: Project, b: Project) => number) | undefined = undefined
        switch (sort) {
            case "state": f = (a,b) => Object.values(State).indexOf(a.state) - Object.values(State).indexOf(b.state) ; break
            case "-state": f = (a,b) => Object.values(State).indexOf(b.state) - Object.values(State).indexOf(a.state) ; break
            case "title": f = (a,b) => a.title.localeCompare(b.title); break
            case "-title": f = (a,b) => b.title.localeCompare(a.title); break
            case "assignee": f = (a,b) => getAssigneeName(a).localeCompare(getAssigneeName(b)); break
            case "-assignee": f = (a,b) => getAssigneeName(b).localeCompare(getAssigneeName(a)); break
            case "updated": f = (a, b) => a.updated - b.updated; break
            case "-updated": f = (a, b) => b.updated - a.updated; break
        }
        return f ? [...p].sort(f) : p
    }

    function SortButtons({field}: {field:string}) {
        return <><Icons.Up className={sort === field ? "active sort":"sort"} onClick={()=> setSort(field)}/><Icons.Down className={sort === `-${field}` ? "active sort":"sort"} onClick={()=> setSort(`-${field}`)}/></>
    }
    
    function countComments(p: Project) : number{
        if (user?.read?.hasOwnProperty(p._id) && p.comments) {
            const ts = user.read[p._id]
            return p.comments.filter(c => c.timestamp > ts).length
        } else {
            return p.comments?.length ?? 0
        }
    }

    useEffect(() => {
        const p = proj_id && projects.get(proj_id)
        if (p) {
            setViewProj(p)
        } else {
            setViewProj(undefined)
        }
    }, [projects, proj_id]);

    useEffect(() => {
        const p = proj_id ? projects.get(proj_id) : undefined
        setNewProj(props.mode === 'new')
        setViewProj(props.mode === 'view' ? p : undefined)
        setCommentsProj(props.mode === 'comments' ? p : undefined)
        setAttachmentProj(props.mode === 'attach' ? p : undefined)
        setAssignProj(props.mode === 'assign' ? p : undefined)
        setStepProj(props.mode === 'step' ? p : undefined)
        setCurrentDir(dir === 'prev' ? 'prev' : 'next')
        if (props.mode === 'comments' && p?.comments && p.comments.length > 0) {
            network.updateRead(p._id, p.comments.map(c => c.timestamp).reduce((a,b) => Math.max(a,b), 0)).then(u => {if (u) setUser(u)})
        }
    }, [projects, props.mode, proj_id, dir, setUser]);

    if (projects.size === 0)
        return <div className='empty'>
            It seems there are no matching projects yet.
            {user?.role === 'admin' && <><br/>Maybe you should <span className='link' onClick={() => navigate('/new')}>Start one</span>?</>}
        </div>
    else return <div className='projects'>
        <div className='row h'>
            <div className='track'>TR</div>
            <div className='state'>Status <SortButtons field={"state"}/></div>
            <div className='title'>Title <SortButtons field={"title"}/></div>
            <div className='assignee'>Assignee <SortButtons field={"assignee"}/></div>
            <div className='deadline'>Deadline <SortButtons field={"deadline"}/></div>
            <div className='delivey'>Delivery <SortButtons field={"delivery"}/></div>
            <div className='updated'>Updated <SortButtons field={"updated"}/></div>
            <div className='actions'><Icons.Refresh onClick={doRefresh} className={refreshing ? "animate" : ""}/></div>
        </div>
        {context && sortProjects(Array.from(projects.values())).map(p => {
            const assignee = getAssignee(p, context.workflow)
            const badge = countComments(p)
            return <div className='row d' key={p._id} onClick={() => navigate(`/project/${p._id}`)}>
                <div className='track' key={`track${p._id}`}><EnumBubble value={p.track} enum={Track}/></div>
                <div className='state' key={`state${p._id}`}><EnumBubble value={p.state} enum={State}/></div>
                <div className='title' key={`title${p._id}`}>{(badge > 0) && <span className='badge'>{badge}</span>}{p.title}</div>
                <div className='assignee' key={`assignee${p._id}`}>{assignee && <UserBubble user={assignee}/>}</div>
                <div className='deadline' key={`deadline${p._id}`}><RelativeTime date={p.deadline}/></div>
                <div className='delivey' key={`delivery${p._id}`}>{new Date(p.delivery).toLocaleString()}</div>
                <div className='updated' key={`updated${p._id}`}>{new Date(p.updated).toLocaleString()}</div>
                <div className='actions' key={`actions${p._id}`} onClick={e => e.stopPropagation()}>
                    <Icons.Back className={getState(p)?.prev ? "" : "disabled"} onClick={() => {if (getState(p)?.prev) navigate(`/project/${p._id}/step/prev`)}}/>
                    <Icons.View onClick={() => navigate(`/project/${p._id}`)}/>
                    <Icons.Attach onClick={() => navigate(`/project/${p._id}/attach`)}/>
                    <Icons.Person className={assignee === user?._id ? "" : "disabled"} onClick={() => {if (assignee === user?._id) navigate(`/project/${p._id}/assign`)}}/>
                    {user?.role ==='admin' && <Icons.Copy onClick={() => navigate(`/new/${p._id}`)}/>}
                    <Icons.Comment onClick={() => navigate(`/project/${p._id}/comments`)}/>
                    <Icons.Forward className={getState(p)?.next ? "" : "disabled"} onClick={() => {if (getState(p)?.next) navigate(`/project/${p._id}/step/next`)}}/>
                </div>
            </div>
        })}
        {newProj && <NewProject/>}
        {viewProj && <ViewProject project={viewProj}/>}
        {stepProj && <Step project={stepProj} dir={currentDir}/>}
        {attachmentProj && <Attachments project={attachmentProj}/>}
        {assignProj && <Assign project={assignProj}/>}
        {commentsProj && <Comments project={commentsProj}/>}
    </div>
}
