import "./UploadPayments.scss"
import { useState } from "react"
import Dialog from "./Dialog"
import { Drop } from "../components/Drop"
import network from "../util/Network"
import { NotificationsAccess } from "../App"

interface Props {
    onClose: ()=>void
}

function defaultNum() {
    const now = new Date()
    const d = new Date(now.getFullYear(), now.getMonth(), 0)
    return `${String(d.getMonth() + 1).padStart(2, '0')}${String(d.getDate()).padStart(2, '0')}${String(d.getFullYear()).slice(-2)}`
}

export function UploadPayments({onClose}:Props) {
    const [invoiceNum, setInvoiceNum] = useState(defaultNum())
    const [file, setFile] = useState<File|undefined>()

    function close() {
        setInvoiceNum(defaultNum())
        setFile(undefined)

        onClose()
    }

    async function upload() {
        if (file) {
            const result = await network.uploadPaymentsFile(await file.arrayBuffer(), invoiceNum)
            if (result) {
                close()
            } else if (NotificationsAccess) {
                NotificationsAccess(ns => ns.concat({level: "error", msg: "Upload failed"}))
            }
        }
    }

    return <Dialog className="upload-payments" onClose={close} dontNavigate={true}>
        <div className="prompt">Upload new payments</div>
        <div className="invoiceNum">Invoice number: <input defaultValue={invoiceNum} onChange={e => setInvoiceNum(e.target.value)}/></div>
        {file ? <div className="file">{file.name}</div>:<Drop drop={setFile} allowed="text/csv">Click to upload a file, or drag and drop here</Drop>}
        <div className='buttons'>
            <div className='cancel button' onClick={close}>Cancel</div>
            <div className={'create button' + (file ? "" : " disabled")} onClick={upload}>Upload</div>
        </div>
    </Dialog>
}