import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import './App.css'
import Header from "./components/Header"
import {usePageVisibility} from 'react-page-visibility'
import Network, { User } from "./util/Network"
import {Project, Workflow} from "./util/Workflow"
import {Loader} from "./components/Loader";
import {Outlet} from "react-router-dom";
import { AppContext } from './util/AppContext'

export interface Notification {msg:string, level: "warning"|"error"}
export let NotificationsAccess : Dispatch<SetStateAction<Array<Notification>>> | undefined = undefined

function App() {
    const pageVisibility = usePageVisibility()
    const [search, setSearch] = useState('')
    const [error, setError] = useState<string>()
    const [notifications, setNotifications] = useState<Array<Notification>>([])
 
    const [users, setUsers] = useState<User[]>([])
    const [workflow, setWorkflow] = useState<Workflow|undefined>(undefined)
    const [user, setUser] = useState<User|undefined>(undefined)
    const [projects, setProjects] = useState<Map<string, Project>>(new Map<string, Project>())

    function updateProjects(ps?: Array<Project>) {
        if (ps) {
            const m = new Map<string, Project>()
            ps.forEach(p => m.set(p._id, p))
            setProjects(m)
        }
    }

    async function loadProjects() {
        return Network.getProjects(search).then(updateProjects)
    }

    function updateProject(p: Project) {
        setProjects(ps => {
            ps.set(p._id, p)
            return new Map(ps)
        })
    }

    function updateSearch(search: string) {
        setSearch(search)
    }

    useEffect(() => {
        if (pageVisibility) {
            Network.getProjects(search).then(updateProjects)
            const id = setInterval(() => {Network.getProjects(search).then(updateProjects)}, 30000)
            return () => clearInterval(id)
        }
    }, [pageVisibility, search])

    useEffect(() => {
        if (!workflow) {
            Network.getCurrentUser().then(setUser).catch(setError)
            Network.getProjects().then(updateProjects).catch(setError)
            Network.getUsers().then(us => {setUsers(us?.sort((a,b) => a.name.localeCompare(b.name)) ?? [])}).catch(setError)
            Network.getWorkflow().then(setWorkflow).catch(setError)
        }
    }, [workflow]);

    NotificationsAccess = setNotifications

    if (error || !projects || !workflow)
        return <div className='loading'>
            <Loader/>
            <div className='error'>{error ?? "Loading..."}</div>
        </div>
    else
        return <div className="App">
        <AppContext.Provider value={{users, workflow, projects, updateProject, refresh: loadProjects, user, setUser}}>
            <Header updateSearch={updateSearch} notifications={notifications}/>
            <Outlet/>
        </AppContext.Provider>
    </div>
}

export default App;
