import "./Header.scss"
import {useContext, useEffect, useState} from "react"
import Network, { version } from "../util/Network"
import {useNavigate} from "react-router-dom"
import {Notification} from "../App";
import {hash} from "../util/Hash";
import { AppContext } from "../util/AppContext"
import Icons from "../util/Icons";

interface Props {
    updateSearch: (search: string) => void
    notifications: Notification[]
}

export default function Header(props:Props) {
    const [search, setSearch] = useState('')
    const [showMenu, setShowMenu] = useState(false)
    const navigate = useNavigate()
    const {user, refresh} = useContext(AppContext)

    useEffect(() => {
        const h = setTimeout(() => props.updateSearch(search), 500)
        return () => clearTimeout(h)
    }, [search, props]);

    function performLogout() {
        Network.logout()
        navigate("/login")
    }

    async function updateLoginAs(role: string) {
        const success = await Network.loginAs(role)
        if (success) {
            await refresh()
        }
        setShowMenu(false)
    }

    return <div className='header'>
        <div className='logo'><Icons.Logo/>Kitchen</div>
        <div className='search'>
            <input type='text' defaultValue={search} onChange={e => setSearch(e.target.value)} placeholder='Search...'/>
        </div>
        {user?.role === 'admin' && <div className='add' onClick={() => navigate('/new')}><Icons.Add/></div>}
        <div className='profile' onClick={() => setShowMenu(!showMenu)}>
            <img src={user?.image ?? `/avatar-0${hash(user?._id ?? "user") % 5}.png`} alt='user'/>
            <div className={`menu${showMenu ? " show" : ""}`}>
                <div>UID: {user?._id}</div>
                <div>Version: {version}</div>
                {(user?.roles?.indexOf('admin') ?? 0) >= 0 && <>
                    <div onClick={() => updateLoginAs("creator")}>Login as creator</div>
                    <div onClick={() => updateLoginAs('admin')}>Login as admin</div>
                </>}
                <div onClick={performLogout}>Logout</div>
            </div>
        </div>
        {props.notifications && <div className="notifications">
            {props.notifications.map((notification, index) => <div key={`notification-${index}`} className={`notification ${notification.level}`}>{notification.msg}</div>)}
        </div>}
    </div>
}